<template>
    <div>
      <div class="hand">
        <div class="hand-left">
          <div class="tip1">机场无人机空域监管平台</div>
          <div class="tip2">自动识别 | 跟踪监视 | 智能管制 | 统一指挥调度</div>
          <div class="tip4">识别无人机、自动测向、自动定位、自动告警，并对非法入侵无人机进行智能管制，有效实现对无人机的预警与管控，保障低空安全、公共安全、重点区域安全和保密安全等，将智能化管控平台逐步与新型智慧城市衔接。</div>
          <div class="tip3">
            <!-- <div class="btn1" @click="pageHref">联系我们</div> -->
            <div class="btn1" @click="viewMore">查看更多</div>
          </div>
        </div>
      </div>
        <!-- 平台特点 -->
        <div class="pttd">
            <div class="title">平台特点</div>
            <div class="content">
                <div class="box1">
                    <div class="box">
                        <div class="tips1">
                            <div class="w-f">{{ pytd1.name }}</div>
                        </div>
                        <div class="flex-dix">
                            <div v-for="(item,index) in pytd1.pytd1List" :key="index" class="flex-div-div">
                                <div class="flex">
                                    <div class="line2"></div>
                                    <div>{{ item.name1 }}</div>
                                </div>
                                <div class="tips2">{{ item.name2 }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box2-div">
                    <div class="box2-div1">
                        <div class="box-2">
                            <div class="box">
                        <div class="tips1">
                            <div>{{ pytd2.name }}</div>
                        </div>
                        <div class="flex-dix">
                            <div v-for="(item,index) in pytd2.pytd1List" :key="index" class="flex-div-div">
                                <div class="flex">
                                    <div class="line2"></div>
                                    <div>{{ item.name1 }}</div>
                                </div>
                                <div class="tips2">{{ item.name2 }}</div>
                            </div>
                        </div>
                    </div>
                        </div>
                        <div class="box-2">
                            <div class="box">
                        <div class="tips1">
                            <div>{{ pytd3.name }}</div>
                        </div>
                        <div class="flex-dix">
                            <div v-for="(item,index) in pytd3.pytd1List" :key="index" class="flex-div-div">
                                <div class="flex">
                                    <div class="line2"></div>
                                    <div>{{ item.name1 }}</div>
                                </div>
                                <div class="tips2">{{ item.name2 }}</div>
                            </div>
                        </div>
                    </div>
                        </div>
                        <div class="box-3">
                            <div class="box">
                        <div class="tips1">
                            <div>{{ pytd4.name }}</div>
                        </div>
                        <div class="flex-dix">
                            <div v-for="(item,index) in pytd4.pytd1List" :key="index" class="flex-div-div">
                                <div class="flex">
                                    <div class="line2"></div>
                                    <div>{{ item.name1 }}</div>
                                </div>
                                <div class="tips2">{{ item.name2 }}</div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                    <div class="box2-div2">
                        <div class="box-4">
                            <div class="box">
                                <div class="tips1">
                                    <div>{{ pytd5.name }}</div>
                                </div>
                                <div class="flex">
                                    <div class="line2"></div>
                                    <div>{{ pytd5.name2 }}</div>
                                </div>
                                <div class="tips2">{{ pytd5.name3 }}</div>
                            </div>
                        </div>
                        <div class="box-5">
                            <div class="box">
                                <div class="tips1">
                                    <div>{{ pytd6.name }}</div>
                                </div>
                                <div class="flex">
                                    <div class="line2"></div>
                                    <div>{{ pytd6.name2 }}</div>
                                </div>
                                <div class="tips2">{{ pytd6.name3 }}</div>
                                <div class="flex">
                                    <div class="line2"></div>
                                    <div>{{ pytd6.name4 }}</div>
                                </div>
                                <div class="tips2">{{ pytd6.name5 }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <!-- 平台优势 -->
      <div class="manage-box">
        <div class="title">平台优势</div>
        <div class="content mb20">
          <div class="box" v-for="(item, i) in manageList" :key="i">
            <div class="flex">
              <div class="tips1">
                <div>{{ item.name }}</div>
                <div class="line">
                  <div class="line2"></div>
                </div>
              </div>
              <img class="img" :src="item.img" />
            </div>
            <div class="tips2">{{ item.tips }}</div>
          </div>
        </div>
        <div class="content">
          <div class="box" v-for="(item, i) in manageList2" :key="i">
            <div class="flex">
              <div class="tips1">
                <div>{{ item.name }}</div>
                <div class="line">
                  <div class="line2"></div>
                </div>
              </div>
              <img class="img" :src="item.img" />
            </div>
            <div class="tips2">{{ item.tips }}</div>
          </div>
          </div>
      </div>
      <div class="bg_div">
          <!-- 平台架构 -->
           <div class="ptjg">
                <div class="title">平台架构</div>
                <div class="content">
                    <img src="@/assets/project/ptjg2banner.png" />
                </div>
           </div>
            <!-- 无人机管控平台 -->
            <div class="ptjg">
                <div class="title">机场无人机空域监管平台</div>
                <div class="content">
                    <img src="@/assets/project/wrjbanner.png" />
                </div>
           </div>
      </div>
        <!-- 侦测设备 -->
        <div class="jcsb">
            <div class="title">侦测设备</div>
            <div class="content">
                <div class="box" v-for="(item, i) in zcsbList" :key="i">
                    <img class="img" :src="item.img" />
                </div>
            </div>
        </div>
        <!-- 反制设备 -->
        <div class="fzsb">
            <div class="title">反制设备</div>
            <div class="content">
                <div class="box" v-for="(item, i) in fzsbList" :key="i">
                    <img class="img" :src="item.img" />
                </div>
            </div>
        </div>
        <!-- 应用场景 -->
        <div class="yycj">
            <div class="title">应用场景</div>
            <div class="content">
                <div class="box">
                    <div class="box-img">
                        <img src="@/assets/project/yycj3.png" class="box-img1"/>
                        <div class="box-title">航空运输</div>
                    </div>
                    <div class="box-img">
                        <img src="@/assets/project/yycj2.png" class="box-img2"/>
                        <div  class="box-title">活动会场</div>
                    </div>
                    <div class="box-img">
                        <img src="@/assets/project/yycj1.png" class="box-img3" />
                        <div class="box-title">边防阵地</div>
                    </div>
                </div>
                <div class="box">
                    <div class="box-img">
                        <img src="@/assets/project/yycj4.png" class="box-img4" />
                        <div class="box-title">石油电力</div>
                    </div>
                    <div class="box-img">
                        <img src="@/assets/project/yycj5.png" class="box-img5"/>
                        <div class="box-title">监管看护</div>
                    </div>
                    <div class="box-img">
                        <img src="@/assets/project/yycj6.png" class="box-img6" />
                        <div class="box-title">高端物业</div>
                    </div>
                </div>
            </div>
        </div>
      <!-- 合作客户 -->
      <div class="client-box">
        <div class="title">合作客户</div>
        <div class="content">
          <img src="@/assets/project/yid.png" alt="" class="img" />
          <img src="@/assets/project/liant.png" alt="" class="img" />
          <img src="@/assets/project/dianx.png" alt="" class="img" />
          <img src="@/assets/project/dat.png" alt="" class="img" />
          <img src="@/assets/project/xink.png" alt="" class="img" />
          <img src="@/assets/project/shuz.png" alt="" class="img" />
          <img src="@/assets/project/chengf.png" alt="" class="img" />
          <img src="@/assets/project/gangt.png" alt="" class="img" />
          <img src="@/assets/project/yanc.png" alt="" class="img" />
          <img src="@/assets/project/xiangt.png" alt="" class="img" />
          <img src="@/assets/project/yuns.png" alt="" class="img" />
          <img src="@/assets/project/shangh.png" alt="" class="img" />
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'project',
    components: {},
    props: {},
    data () {
      return {
        manageList: [
          {
            name: '城市级的网格化监控',
            tips: 'GIS地图实时呈现侦测设备、干扰设备、无人机、执法人员等资源分布',
            img: require('../assets/project/jk.png')
          },
          {
            name: '实时的监控与预警',
            tips: 'GIS地图实时呈现侦测设备、干扰设备、无人机、执法人员等资源分布',
            img: require('../assets/project/yj.png')
          },
          {
            name: '完善的预案流程',
            tips: '结合业务数据，整合形成具有体系化、逻辑化、自动匹配型的智能数字化应急预案，为各级应急响应岗位具有可操作性的智慧型应急救援处置预案应用模式。',
            img: require('../assets/project/lc.png')
          }
        ],
        manageList2:[
         {
            name: '多应用端的协同式事件处理',
            tips: '平台端、移动端、设备端多平台应用，贯穿事前-应急准备、事中-应急响应、事后-总结评估全过程，覆盖事件业务管理闭环。',
            img: require('../assets/project/cl.png')
          },
          {
            name: '智能感知的辅助侦测',
            tips: '通过感知节点实现无人机的探测识别和远距预警，基于算法实现目标的精准定位，支持跨区域持续跟踪锁定目标。',
            img: require('../assets/project/zc.png')
          },
          {
            name: '雄厚的技术实力',
            tips: '国防科技大学博士领衔的研发团队，在无人机监测设备与监管领域技术研究近十年',
            img: require('../assets/project/js.png')
          }
        ],
        zcsbList:[
            {
                img: require('../assets/project/1@2x.png')
            },
            {
                img: require('../assets/project/2@2x.png')
            },
            {
                img: require('../assets/project/3@2x (1).png')
            }
        ],
        fzsbList:[
            {
                img: require('../assets/project/4@2x.png')
            },
            {
                img: require('../assets/project/5@2x.png')
            },
            {
                img: require('../assets/project/6@2x.png')
            }
        ],
        pytd5:{
            name:'高效联动',
            name2:'协调机制',
            name3:'遇到需要多方面协作解决的问题时，能够迅速启动协调机制，打破信息孤岛，实现资源共享、行动一致，确保在面对复杂情况时能够高效有序地开展工作。'
        },
        pytd6:{
            name:'黑白名单管理系统',
            name2:'黑名单拦截策略',
            name3:'建立非法无人机数据库，对黑名单无人机严格监控与主动拦截，有效阻止其进入限制或禁飞区域，保障空域安全。',
            name4:'白名单准入机制',
            name5:'为已注册的合法无人机设立白名单，自动放行并减少监控资源占用，为合法飞行活动提供便利。',
        },
        pytd1:{
            name:'大区域网格化部署',
            pytd1List:[
                {
                    name1:'精细化管理',
                    name2:'通过网格划分，针对每个小区域的具体情况进行个性化管理和优化'
                },
                {
                    name1:'高效资源分配',
                    name2:'网格化使得资源可以根据各个网格的实际需求灵活调配'
                },
                {
                    name1:'快速应急响应',
                    name2:'紧急情况时，网格化系统能够迅速定位问题所在的网格，立即调动该网格。'
                },
                {
                    name1:'信息共享与协作',
                    name2:'网格之间建立起高效的沟通和信息共享机制，促进跨网格的协同作业。'
                }
            ]
        },
        pytd2:{
            name:'多架次跟踪与并发处理能力',
            pytd1List:[
                {
                    name1:'并发目标处理',
                    name2:'采用高性能计算架构，在多架无人机同时飞行场景下，也能精准识别、独立跟踪与有效管理，避免漏报与误报。'
                },
                {
                    name1:'动态资源调配',
                    name2:'根据跟踪任务的优先级与资源占用情况，智能调配监控与反制资源，确保目标无人机都能有效监控与及时响应。'
                }
            ]
        },
        pytd3:{
            name:'多级防御体系',
            pytd1List:[
                {
                    name1:'层次化防御策略',
                    name2:'构建由外围预警、中层拦截到核心防护的多级防御网，每级配备相应的侦测与反制措施，层层设防。'
                },
                {
                    name1:'自适应防御强度',
                    name2:'系统根据无人机威胁等级自动调整防御策略的强度与范围，有效平衡安全需求与资源消耗，实现智能化防护。'
                }
            ]
        },
        pytd4:{
            name:'无人值守自动化操作',
            pytd1List:[
                {
                    name1:'自动化作业',
                    name2:'支持无人值守模式，通过预设算法自动执行日常监控、预警及基础反制操作，减轻人力依赖，提高运营效率。'
                },
                {
                    name1:'远程监控与控制',
                    name2:'管理人员可通过远程终端实时查看系统状态，进行远程操控与策略调整，实现灵活高效的远程管理。'
                }
            ]
        }
      }
    },
    created () {},
    computed: {},
    methods: {
      pageHref () {
        this.$router.push({
          path: '/tech/about',
          query: {
            vs: 1
          }
        })
      },
      viewMore () {
        this.$router.push('/tech/about')
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .hand {
    position: relative;
    background-image: url("../assets/project/banner-wrj.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 566px;
    width: 100%;
    overflow: hidden;
    .hand-left {
      position: relative;
      left: 10.8%;
      top: 105px;
      .tip1 {
        font-family: Alimama ShuHeiTi, Alimama ShuHeiTi;
        font-weight: bold;
        font-size: 72px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
      }
  
      .tip2 {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 32px;;
        color: #FFFFFF;
        margin-top: 28px;
        letter-spacing: 2px;
        font-style: normal;
        text-transform: none;
      }
      .tip4{
        margin-top: 28px;
        width: 688px;
        height: 96px;
        font-weight: 400;
        font-size: 20px;
        color: #F5F5F5;

      }
      .tip3 {
        margin-top: 38px;
        display: flex;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
  
        .btn1 {
          width: 180px;
          height: 50px;
          background: #ff892e;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #ff892e;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .btn2 {
          width: 110px;
          height: 50px;
          background: rgba(255, 137, 46, 0);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 11px;
          cursor: pointer;
        }
      }
    }
  }
  .manage-box {
    height: 746px;
    width: 100%;
    position: relative;
    background-image: url("../assets/project/ptys_banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 42px;
      color: #000000;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin: 68px auto 44px auto;
      height: fit-content;
    }
    .mb20{
        margin-bottom: 20px !important;
    }
    .content {
      display: flex;
      margin: 0px auto;
      .box {
        transition: all 1.2s ease;
        width: 453px;
        height: 240px;
        background: linear-gradient(
          133deg,
          rgba(251, 252, 254, 0.94) 0%,
          #f6f9ff 100%
        );
        border-radius: 8px 8px 8px 8px;
        margin: 0px 12px;
        padding: 25px 24px 20px 40px;
        display: flex;
        flex-direction: column;
        .flex {
          display: flex;
        }
        .tips1 {
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: bold;
          font-size: 24px;
          color: #252d47;
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        .line {
          width: 55px;
          height: 3px;
          display: flex;
          margin-top: 16px;
          .line2 {
            width: 20px;
            height: 4px;
            background: #FF892E;
          }
        }
        .img {
          width: 80px;
          height: 80px;
        }
        .tips2 {
            font-weight: 400;
            font-size: 16px;
            color: #778293;
            line-height: 28px;
            text-align: left;
        }
      }
      .box:hover {
        transition: all 1.2s ease-out;
        background: #4a8afc;
        box-shadow: 0px 8px 16px 1px rgba(46, 121, 255, 0.3);
        .tips1,
        .tips2 {
          color: #ffffff;
        }
        .line1 {
          background: #ffffff;
        }
      }
    }
  }
  .client-box {
    min-height: 508px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 42px;
      color: #000000;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin: 91px auto 52px auto;
      height: fit-content;
    }
    .content {
      display: grid;
      grid-template-columns: repeat(6, 180px);
      grid-column-gap: 64px;
      margin: 0px auto;
      .img {
        width: 180px;
        height: 84px;
      }
    }
  }
  .wh100 {
    width: 100%;
    height: 100%;
  }
  .bg_div{
    width: 100%;
    background: linear-gradient( 180deg, #FFFFFF 0%, #E6EFFF 100%);
    border-radius: 0px 0px 0px 0px;
    .ptjg{
        min-height: 600px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        .title {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 42px;
            color: #000000;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin: 68px auto 44px auto;
            height: fit-content;
        }
        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 86px;
            img{
                width: 1400px;
                height: 788px;
            } 
        }

    }
  }
  .jcsb,.fzsb{
        min-height: 600px;
        width: 100%;
        position: relative;
        .title {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 42px;
            color: #000000;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin: 68px auto 44px auto;
            height: fit-content;
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .box{
                margin: 0px 35px;
                img {
                    width: 420px;
                    height: 418px;
                }
            }
        }

  }
  .yycj{
        min-height: 600px;
        width: 100%;
        background: #F5F8FF;
        padding: 72px 0 102px 0;
        .title {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 42px;
            color: #000000;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin: 0 auto 40px auto;
            height: fit-content;
        }
        .content{
            .box{
                display: flex;
                justify-content: center;
            }
            .box-img{
                position: relative;
                margin: 5px;
            }
            .box-title{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50px;
                font-weight: bold;
                font-size: 20px;
                color: #FFFFFF;
                line-height: 32px;
                text-align: center;
                background: linear-gradient( 180deg, rgba(0,33,59,0) 0%, rgba(0,33,59,0.8) 100%);
                border-radius: 0px 0px 0px 0px;
            }
            .box-img1{
                width: 394px;
                height: 396px;
            }
            .box-img2{
                width: 626px;
                height: 396px;
            }
            .box-img3{
                width: 356px;
                height: 396px;
            }
            .box-img4{
                width: 706px;
                height: 397px;
            }
            .box-img5{
                width: 317px;
                height: 397px;
            }
            .box-img6{
                width: 353px;
                height: 397px;
            }
        }
  }
  .pttd{
    width: 100%;
    position: relative;
    background-image: url("../assets/project/pttdbanner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    .title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 42px;
      color: #000000;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin: 64px auto 44px auto;
      height: fit-content;
    }
    .content{
        padding: 0 260px;
    }
    .box1{
        background-image: url("../assets/project/pttd1.png"); 
        width: 100%;
        height: 196px;
        background-size: 100% 100%;
        margin-bottom: 20px;
        padding: 28px 32px;
    }
    .box-2{
        background-image: url("../assets/project/pttd2.png"); 
        width: 100%;
        height: 179px;
        background-size: 100% 100%;
        margin-bottom: 20px;
        padding: 28px 32px;
    }
    .box-3{
        background-image: url("../assets/project/pttd3.png"); 
        width: 100%;
        height: 179px;
        background-size: 100% 100%;
        padding: 28px 32px;
    }
    .box-4{
        background-image: url("../assets/project/pttd4.png"); 
        width: 100%;
        height: 236px;
        background-size: 100% 100%;
        margin-bottom: 20px;
        padding: 28px 32px;
    }
    .box-5{
        background-image: url("../assets/project/pttd5.png"); 
        width: 100%;
        height: 320px;
        background-size: 100% 100%;
        padding: 28px 32px;
    }
    .box2-div{
        display: flex;
        justify-content: space-around;
    }
    .box2-div1{
        width: 972px;
        margin-right: 20px;
    }
    .box2-div2{
        width: 408px;
    }
      .flex {
          display: flex;
          margin: 20px 0 15px 0;
          font-family: Source Han Sans CN, Source Han Sans CN;
            font-weight: 500;
            font-size: 18px;
            color: #0B1E2F;
        }
        .tips1 {
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: bold;
          font-size: 24px;
          color: #252d47;
        }
        .line2 {
            width: 20px;
            height: 4px;
            background: #FF892E;
            margin-top: 10px;
            margin-right: 5px;
          }
        .img {
          width: 80px;
          height: 80px;
        }
        .tips2 {
            font-weight: 400;
            font-size: 16px;
            color: #778293;
            line-height: 24px;
            text-align: left;
        }
        .flex-dix{
            display: flex;
        }
        .flex-div-div{
            margin-right: 11px;
        }
        .w-f{
            color: #fff;
        }
  }
  </style>
  